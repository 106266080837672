import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
    constructor(
        public router: Router,
        protected storage: Storage,
        protected authentication: AuthenticationService,
    ) {
    }
    /**
     * check user can access inner pages
     */
    canActivate() {
        console.log('AuthenticationGuard');
        //return true;
        return this.authentication.getToken()
            .then((user) => {
                if (!user) {
                    this.router.navigateByUrl('/login');
                    return false;
                }
                return true;
            });
    }
}