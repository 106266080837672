import { Component, OnInit } from '@angular/core';
import {LeadsService} from "../../services/leads.service";
import {ModalController, NavParams, ToastController} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-add-to-leads',
  templateUrl: './add-to-leads.page.html',
  styleUrls: ['./add-to-leads.page.scss'],
})
export class AddToLeadsPage implements OnInit {
  quoteParams;
  leadForm: FormGroup;

  constructor(protected modalCtrl: ModalController,
              protected formBuilder: FormBuilder,
              protected toastCtrl: ToastController) {
    this.leadForm = formBuilder.group({
      clientName: ['', Validators.required],
    });
  }

  ngOnInit() {
  }
  async addToLeads() {
    console.log(this.leadForm.value);
    if(this.leadForm.valid) {
      await this.modalCtrl.dismiss({data: this.leadForm.value.clientName});
    } else {
      let toast = await this.toastCtrl.create({
        message: 'Please enter client name',
        duration: 3000,
        position: 'bottom'
      });
      toast.present();
    }
  }
  async dismiss(e) {
    e.preventDefault();
    await this.modalCtrl.dismiss()
  }
}
