import { KycVerificationService } from './kyc-verification.service';
import { Injectable } from '@angular/core';
import {ApiServiceService} from "./api-service.service";
import {map} from 'rxjs/operators';
import {Storage} from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  token;

  constructor(protected storage: Storage,
              protected api: ApiServiceService,
              protected kycService: KycVerificationService) {
                this.storage.create();
               }

  /**
   * check user exists or not
   */
  async userExists() {
    return await this.storage.get('user');
  }

  /**
   * get access token
   */
  async getToken() {
    // console.log('auth:',this.storage.get('access_token'))
    // return await this.storage.get('access_token').then( data => {
    //   return data;
    // })
    return await this.storage.get('access_token');
  }
  async getUserKycDetails() {
    return this.kycService.getKYCInformation()
  }
}
