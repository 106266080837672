import { Injectable } from '@angular/core';
import {ApiServiceService} from "./api-service.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  baseUrl;
  headers;
  options;

  constructor(protected api: ApiServiceService, protected httpClient: HttpClient) {
    this.baseUrl = environment.apiUrl;
    this.headers = new HttpHeaders({
      'Content-Type': `application/json`,
      'Accept': `application/json`,
      'X-Requested-With': `XMLHttpRequest`
    });
    this.options = {
      headers: this.headers
    };
  }
  /**
   * save fcm token
   */
  saveFCMToken(device_token) {
    return this.api.post('pos/firebase/save-token', {device_token: device_token}, this.options )
        .pipe(
            map((d: any) => d)
        );
  }
}
