import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddToLeadsPageRoutingModule } from './add-to-leads-routing.module';

import { AddToLeadsPage } from './add-to-leads.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AddToLeadsPageRoutingModule,
        ReactiveFormsModule
    ],
  declarations: [AddToLeadsPage]
})
export class AddToLeadsPageModule {}
