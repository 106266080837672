import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {fromPromise} from 'rxjs/internal-compatibility';
import {mergeMap, scan} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(public authentication: AuthenticationService, protected  router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('intersept:', req.url.includes(environment.apiBaseUrl));
        // console.log('intersept api:', req.url.includes(environment.apiUrl));
        if (req.url.includes(environment.apiBaseUrl)) {
            return fromPromise(this.authentication.getToken())
                .pipe(mergeMap(this.appendsAuthenticationHeaders(req, next)));
        }
        else if (req.url.includes(environment.apiUrl)) {
            return fromPromise(this.authentication.getToken())
                .pipe(mergeMap(this.appendsAuthenticationHeaders(req, next)));
        }
        return next.handle(req);
    }

    /**
     * set http headers for api request
     * @param req
     * @param next
     */
    appendsAuthenticationHeaders(req: HttpRequest<any>, next: HttpHandler) {
        return (user) => {
            if (user) {
                // console.log('resolver',user);
                const headers = req.headers
                    .append('Authorization', `Bearer ${user}`)
                    .append('Access-Control-Allow-Headers', 'Content-Type')
                    .append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE')
                    .append('Access-Control-Allow-Origin', '*');

                req = req.clone({
                    headers
                });
            }
            return next.handle(req);
        };
    }
}
