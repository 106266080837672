const readableKycStatus = (statusId : number) => {
    let kycStatusLabel;
    let kycStatus;
    switch(statusId) { // (1) Not Submitted, (2) Pending Approval, (3) Approved, (4) Rejected, (5) Resubmitted
        case 1:
            kycStatus = 'notSubmitted';
            kycStatusLabel = 'Not Submitted'
            break;
        case 2:
            kycStatus = 'pendingApproval';
            kycStatusLabel = 'Pending Approval'
            break;
        case 3:
            kycStatus = 'approved';
            kycStatusLabel = 'Approved'
            break;
        case 4:
            kycStatus = 'rejected';
            kycStatusLabel = 'Rejected'
            break;
        case 5:
            kycStatus = 'reSubmitted';
            kycStatusLabel = 'Re Submitted'
            break;
        case 6:
            kycStatus = 'examStarted';
            kycStatusLabel = 'Exam Started'
            break;
        case 7:
            kycStatus = 'trainingInProgress';
            kycStatusLabel = 'Training In Progress'
            break;
        case 8:
            kycStatus = 'onboardingCompleted';
            kycStatusLabel = 'Onboarding Completed'
            break;
        case 9:
            kycStatus = 'trainingCompleted';
            kycStatusLabel = 'Training Completed'
            break;
        case 10: //exam failed
            kycStatus = 'examFailed';
            kycStatusLabel = 'Exam Failed'
            break;
        case 11: //exam failed
            kycStatus = 'examCompleted';
            kycStatusLabel = 'Exam Completed'
            break;
        default:
            kycStatus = 'notSubmitted';
            kycStatusLabel = 'Not Submitted'
            break;
    }
    return { kycStatus, kycStatusLabel}
}
export { readableKycStatus };