import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams, ToastController} from "@ionic/angular";
import { Storage } from '@ionic/storage-angular';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-confirm-buy',
  templateUrl: './confirm-buy.page.html',
  styleUrls: ['./confirm-buy.page.scss'],
})
export class ConfirmBuyPage implements OnInit {
  date: string;
  type: 'string';
  manufacturingDate;
  manufacturingDay;
  manufacturingMonth;
  manufacturingYear;
  registrationDate;
  regisDay;
  regisMonth;
  regisYear;
  registrationDateLabel = 'Registration Date';
  expiryDate;
  expDay;
  expMonth;
  expYear;
  expiryDateLabel = 'Expiry Date of your Previous Policy';
  isClaimed = 0;
  previousPolicyType;
  policy_type_id;
  previous_ncb;
  newNCB;
  policy_start_date;
  is_new_vehicle;
  registration_year_id;
  claimBonus = [{id: 0, value: '0%'}, {id: 20, value: '20%'}, {id: 25, value: '25%'}, {id: 35, value: '35%'}, {id: 45, value: '45%'}, {id: 50, value: '50%'}];
  ncbValue;
  quoteDataValue;
  yearData: any = [];
  previousNcbBlock = true;
  claimBlock = true;
  monthData: any = [{key: '0', value: 'Select'}, {key: '01', value: 'January'}, {key: '02', value: 'February'}, {key: '03', value: 'March'},
    {key: '04', value: 'April'}, {key: '05', value: 'May'}, {key: '06', value: 'June'}, {key: '07', value: 'July'}, {key: '08', value: 'August'},
    {key: '09', value: 'September'},{key: '10', value: 'October'},{key: '11', value: 'November'} ,{key: '12', value: 'December'}];
  constructor(protected params: NavParams,
              protected storage: Storage,
              public datePipe: DatePipe,
              protected toastCtrl: ToastController,
              protected modalCtrl: ModalController) { }

  ngOnInit() {
    let currentYear = new Date().getFullYear();
    currentYear = currentYear - 15;
    for (let i = 0; i<= 15; i++) {
      this.yearData[i] = {key: currentYear + 1, value: currentYear + 1};
      currentYear++;
    }
    this.yearData = this.yearData.reverse();
    this.manufacturingDate = this.params.get('date_of_manufactured');
    this.registrationDate = this.params.get('registration_date');
    this.registration_year_id = this.params.get('registration_year_id');
    this.expiryDate = this.params.get('expiry_date_of_previous_policy');
    this.isClaimed = this.params.get('isClaimed');
    this.previousPolicyType = this.params.get('previous_policy_type_id');
    this.policy_type_id = this.params.get('policy_type_id');
    this.previous_ncb = this.params.get('previous_ncb');
    this.ncbValue = this.previous_ncb;
    this.policy_start_date = this.params.get('policy_start_date');
    this.is_new_vehicle = this.params.get('is_new_vehicle');
    const dataValue = this.params.get('quoteDataValue');
    this.quoteDataValue = JSON.parse(dataValue);
    this.dataBasedonRegistrationYear(this.registration_year_id);
    if (this.is_new_vehicle === 1) {
      this.registrationDateLabel = 'Date Of Sale';
      this.expiryDateLabel = 'New Policy Start Date';
      this.expiryDate = this.policy_start_date;
    }
    if (this.manufacturingDate) {
      const arr = this.manufacturingDate.split('-');
      this.manufacturingDay = arr[0];
      // this.manufacturingMonth = arr[1];
      this.manufacturingYear = arr[2];
      if(arr[1].length === 1) {
        this.manufacturingMonth = '0' + arr[1];
      } else {
        this.manufacturingMonth = arr[1];
      }
      this.manufacturingDate = this.manufacturingDay +'-'+this.manufacturingMonth+'-'+this.manufacturingYear;
    }
    if (this.registrationDate) {
      const arr = this.registrationDate.split('-');
      this.regisDay = arr[0];
      this.regisMonth = arr[1];
      this.regisYear = arr[2];
      if(arr[1].length === 1) {
        this.regisMonth = '0' + arr[1];
      } else {
        this.regisMonth = arr[1];
      }
    }
    if (this.expiryDate) {
      const arr = this.expiryDate.split('-');
      this.expDay = arr[0];
      this.expMonth = arr[1];
      this.expYear = arr[2];
      if(arr[1].length === 1) {
        this.expMonth = '0' + arr[1];
      } else {
        this.expMonth = arr[1];
      }
    }
  }
  dataBasedonRegistrationYear(regisYear){
    if (parseInt(regisYear) === 2020) {
      this.claimBonus = [{id: 0, value: '0%'}];
      this.previous_ncb = 0;
      this.newNCB = '20%';
    } else if (parseInt(regisYear) === 2019) {
      this.claimBonus = [{id: 0, value: '0%'}, {id: 20, value: '20%'}];
      this.previous_ncb = 20;
      this.newNCB = '25%';
    } else if (parseInt(regisYear) === 2018) {
      this.claimBonus = [{id: 0, value: '0%'}, {id: 20, value: '20%'}, {id: 25, value: '25%'}];
      this.previous_ncb = 25;
      this.newNCB = '35%';
    } else if (parseInt(regisYear) === 2017) {
      this.claimBonus = [{id: 0, value: '0%'}, {id: 20, value: '20%'}, {id: 25, value: '25%'}, {id: 35, value: '35%'}];
      this.previous_ncb = 35;
      this.newNCB = '45%';
    } else if (parseInt(regisYear) === 2016) {
      this.claimBonus = [{id: 0, value: '0%'}, {id: 20, value: '20%'}, {id: 25, value: '25%'}, {id: 35, value: '35%'}, {id: 45, value: '45%'}];
      this.previous_ncb = 45;
      this.newNCB = '50%';
    }  else if (parseInt(regisYear) <= 2015) {
      this.claimBonus = [{id: 0, value: '0%'}, {id: 20, value: '20%'}, {id: 25, value: '25%'}, {id: 35, value: '35%'}, {id: 45, value: '45%'}, {id: 50, value: '50%'}];
      this.previous_ncb = 50;
      this.newNCB = '50%';
    }
  }
  async dateValueChanged(type) {
   if (type === 'date') {
     if (this.manufacturingDay < 1 || this.manufacturingDay > 31) {
       await this.displayToastMessage('Invalid date');
       this.manufacturingDay = '';
     } else {
       this.manufacturingDate = this.manufacturingDay +'-'+this.manufacturingMonth+'-'+this.manufacturingYear;
     }
   } else if (type === 'month') {
     if (this.manufacturingMonth === 0 || this.manufacturingMonth === '0') {
       await this.displayToastMessage('Please select Month');
     } else {
       this.manufacturingDate = this.manufacturingDay +'-'+this.manufacturingMonth+'-'+this.manufacturingYear;
     }
   } else if (type === 'year') {
     if (this.manufacturingYear > new Date().getFullYear()) {
       await this.displayToastMessage('Invalid date');
       this.manufacturingYear = '';
     } else {
       this.manufacturingDate = this.manufacturingDay +'-'+this.manufacturingMonth+'-'+this.manufacturingYear;
     }
     console.log(this.registrationDate, this.manufacturingDate);
   }
  }
  async dateValueChangedReg(type) {
    if (type === 'date') {
      if (this.regisDay < 1 || this.regisDay > 31) {
        await this.displayToastMessage('Invalid date');
        this.regisDay = '';
      } else {
        this.registrationDate = this.regisDay +'-'+this.regisMonth+'-'+this.regisYear;
      }
    } else if (type === 'month') {
      if (this.regisMonth === 0 || this.regisMonth === '0') {
        await this.displayToastMessage('Please select Month');
      } else {
        this.registrationDate = this.regisDay +'-'+this.regisMonth+'-'+this.regisYear;
      }
    } else if (type === 'year') {
      if (this.regisYear > new Date().getFullYear()) {
        await this.displayToastMessage('Invalid date');
        this.regisYear = '';
      } else {
        this.registrationDate = this.regisDay +'-'+this.regisMonth+'-'+this.regisYear;
      }
      if (this.manufacturingYear > this.regisYear) {
        this.manufacturingYear = this.regisYear;
        const arr = this.manufacturingDate.split('-');
        this.manufacturingDay = arr[0];
        if(arr[1].length === 1) {
          this.manufacturingMonth = '0' + arr[1];
        } else {
          this.manufacturingMonth = arr[1];
        }
        this.manufacturingDate = this.manufacturingDay +'-'+this.manufacturingMonth+'-'+this.manufacturingYear;
      }
      this.dataBasedonRegistrationYear(this.regisYear);
      const data = moment(this.registrationDate, 'DD-MM-YYYY').diff(moment(this.manufacturingDate, 'DD-MM-YYYY'), 'years');
      console.log(data);
      console.log(this.registrationDate, this.manufacturingDate);
    }
  }
  async dateValueChangedExp(type) {
    if (type === 'date') {
      if (this.expDay < 1 || this.expDay > 31) {
        await this.displayToastMessage('Invalid date');
        this.expDay = '';
      } else {
        this.expiryDate = this.expDay +'-'+this.expMonth+'-'+this.expYear;
      }
    } else if (type === 'month') {
      if (this.expMonth === 0 || this.expMonth === '0') {
        await this.displayToastMessage('Please select Month');
      } else {
        this.expiryDate = this.expDay +'-'+this.expMonth+'-'+this.expYear;
      }
    } else if (type === 'year') {
      if (this.expYear > new Date().getFullYear()) {
        await this.displayToastMessage('Invalid date');
        this.expYear = '';
      } else {
        this.expiryDate = this.expDay +'-'+this.expMonth+'-'+this.expYear;
      }
      // let someDate = new Date();
      // const numberOfDaysToAdd = 90;
      // someDate.setDate(someDate.getDate() - numberOfDaysToAdd);
      // const expireDay = moment(someDate).format('DD-MM-YYYY');
      // console.log(expireDay);
      // const given = new Date(expireDay).getDate();
      // const current = new Date(this.expiryDate).getDate();
      //
      // console.log(current - given);
    }
  }
  async displayToastMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }
  async close() {
    await this.modalCtrl.dismiss();
  }
  async getManufacturingDate() {
    // const modal = await this.modalCtrl.create({
    //   component: DatePickerPage,
    //   backdropDismiss: true,
    //   componentProps: {},
    // });
    // modal.onDidDismiss()
    //     .then((data) => {
    //       if (data.data) {
    //         console.log(data.data);
    //       }
    //     });
    // await modal.present();
  }
  async getNewQuote() {
    const arr = this.newNCB.split('%');
    const newNcb = arr[0];
    let policyStartDate;
    if (this.is_new_vehicle === 1) {
      policyStartDate = this.expiryDate;
      this.expiryDate = null;
    } else {
      policyStartDate = null;
    }
    const param = {vehicle_type: this.quoteDataValue.vehicle_type, make_id: this.quoteDataValue.make_id, variant_id: this.quoteDataValue.variant_id,
      fuel_type_id: this.quoteDataValue.fuel_type_id, model_id: this.quoteDataValue.model_id, registration_year_id: this.quoteDataValue.registration_year_id,
      rto_id: this.quoteDataValue.rto_id,
      previous_ncb: parseInt(this.ncbValue), new_ncb: newNcb, policy_type_id: this.quoteDataValue.policy_type_id, is_expired: this.quoteDataValue.is_expired,
      is_above_90_days: this.quoteDataValue.is_above_90_days, is_new_vehicle: this.quoteDataValue.is_new_vehicle, expiry_date_of_previous_policy: this.expiryDate,
      registration_date: this.registrationDate, previous_policy_type_id: this.previousPolicyType,
      date_of_manufactured: this.manufacturingDate, policy_start_date: policyStartDate};
    await this.modalCtrl.dismiss(param);
  }
  claimChanged(id) {
    this.isClaimed = id;
    this.previousNcbBlock = id !== 1;
  }
  policyTypeChanged(id) {
    this.previousNcbBlock = id !== 3;
    this.claimBlock = id !== 3;
    this.previousPolicyType = id;
  }
  currentPolicyTypeChanged(id) {
    this.policy_type_id = id;
  }
  ncbValueChanged(event) {
    this.ncbValue = event.target.value;
    switch(parseInt(this.ncbValue)){
      case 0:
        this.newNCB = '20%';
            break;
      case 20:
        this.newNCB = '25%';
        break;
      case 25:
        this.newNCB = '35%';
        break;
      case 35:
        this.newNCB = '45%';
        break;
      case 45:
        this.newNCB = '50%';
        break;
      case 50:
        this.newNCB = '50%';
        break;
      default:
        break;
    }
  }
}
