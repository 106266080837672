import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ConfirmBuyPageRoutingModule } from './confirm-buy-routing.module';

import { ConfirmBuyPage } from './confirm-buy.page';
import {CalendarModule} from "ion2-calendar";
import {IonicSelectableModule} from "ionic-selectable";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ConfirmBuyPageRoutingModule,
        CalendarModule,
        IonicSelectableModule,
    ],
  declarations: [ConfirmBuyPage]
})
export class ConfirmBuyPageModule {}
