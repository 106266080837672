import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {Storage} from '@ionic/storage';
import { readableKycStatus } from '../helpers/kycStatus';

@Injectable({
    providedIn: 'root'
})
export class OtpVerificationGuard implements CanActivate {
    constructor(
        public router: Router,
        protected storage: Storage,
        protected authentication: AuthenticationService,
    ) {
    }
    /**
     * check user can access inner pages
     */
    canActivate() {
        return Promise.all([this.authentication.getToken(), this.authentication.getUserKycDetails()]).then((values) => {
            if (values[0]) {
                values[1].subscribe(data => {
                    let kycStatus = readableKycStatus(data.latest_kyc_status?.kyc_status_id)
                    if(kycStatus.kycStatus === 'onboardingCompleted' || kycStatus.kycStatus === 'examCompleted') {
                        this.router.navigateByUrl('/overview');
                        return false;
                    } else {
                        this.router.navigate(['cover-page'], { queryParams: {'kyc_status': data.latest_kyc_status ?  data.latest_kyc_status.kyc_status_id : 0}});
                        return false;
                    }
                }, error => {
                    if(error.status === 401) {
                        this.storage.clear()
                        this.router.navigateByUrl('/login')
                    }
                })
                return false;
                
            }
            return true;
        })
        // this.authentication.getToken()
        //     .then((user) => {
                
        //     });
    }
}
