import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/AuthenticationGuard';
import { ImpersonateGuard } from './guards/ImpersonateGaurd';
import { IntroGuard } from './guards/intro.guard';
import { OtpVerificationGuard } from './guards/OtpVerificationGuard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'splashscreen',
    loadChildren: () => import('./pages/splashscreen/splashscreen.module').then( m => m.SplashscreenPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule),
    
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [OtpVerificationGuard]
  
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'otp-verification',
    loadChildren: () => import('./pages/otp-verification/otp-verification.module').then( m => m.OtpVerificationPageModule),
    canActivate: [OtpVerificationGuard]
  },
  {
    path: 'kycverification',
    loadChildren: () => import('./pages/kycverification/kycverification.module').then( m => m.KYCVerificationPageModule)
  },
  {
    path: 'cover-page',
    loadChildren: () => import('./pages/cover-page/cover-page.module').then( m => m.CoverPagePageModule)
  },
  {
    path: 'main-course',
    loadChildren: () => import('./pages/main-course/main-course.module').then( m => m.MainCoursePageModule)
  },
  {
    path: 'chapters',
    loadChildren: () => import('./pages/chapters/chapters.module').then( m => m.ChaptersPageModule)
  },
  {
    path: 'chapter-details',
    loadChildren: () => import('./pages/chapter-details/chapter-details.module').then( m => m.ChapterDetailsPageModule)
  },
  {
    path: 'overview',
    loadChildren: () => import('./pages/overview/overview.module').then( m => m.OverviewPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'leads',
    loadChildren: () => import('./pages/leads/leads.module').then( m => m.LeadsPageModule)
  },
  {
    path: 'leads-details',
    loadChildren: () => import('./pages/leads-details/leads-details.module').then( m => m.LeadsDetailsPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'business',
    loadChildren: () => import('./pages/business/business.module').then( m => m.BusinessPageModule)
  },
  {
    path: 'earnings',
    loadChildren: () => import('./pages/earnings/earnings.module').then( m => m.EarningsPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'notification-details',
    loadChildren: () => import('./pages/notification-details/notification-details.module').then( m => m.NotificationDetailsPageModule)
  },
  {
    path: 'quotes',
    loadChildren: () => import('./pages/quotes/quotes.module').then( m => m.QuotesPageModule)
  },
  {
    path: 'policy-details',
    loadChildren: () => import('./pages/policy-details/policy-details.module').then( m => m.PolicyDetailsPageModule)
  },
  {
    path: 'quote-enquiry',
    loadChildren: () => import('./pages/quote-enquiry/quote-enquiry.module').then( m => m.QuoteEnquiryPageModule)
  },
  {
    path: 'quick-quote',
    loadChildren: () => import('./pages/quick-quote/quick-quote.module').then( m => m.QuickQuotePageModule)
  },
  {
    path: 'monthly-earnings',
    loadChildren: () => import('./pages/monthly-earnings/monthly-earnings.module').then( m => m.MonthlyEarningsPageModule)
  },
  {
    path: 'booked-transactions',
    loadChildren: () => import('./pages/booked-transactions/booked-transactions.module').then( m => m.BookedTransactionsPageModule)
  },
  {
    path: 'transaction-details',
    loadChildren: () => import('./pages/transaction-details/transaction-details.module').then( m => m.TransactionDetailsPageModule)
  },
  {
    path: 'proposal',
    loadChildren: () => import('./pages/proposal/proposal.module').then( m => m.ProposalPageModule)
  },
  {
    path: 'business-details',
    loadChildren: () => import('./pages/business-details/business-details.module').then( m => m.BusinessDetailsPageModule)
  },
  {
    path: 'payment-summary',
    loadChildren: () => import('./pages/payment-summary/payment-summary.module').then( m => m.PaymentSummaryPageModule)
  },
  {
    path: 'quote-comparison',
    loadChildren: () => import('./pages/quote-comparison/quote-comparison.module').then( m => m.QuoteComparisonPageModule)
  },
  {
    path: 'exam',
    loadChildren: () => import('./pages/exam/exam.module').then( m => m.ExamPageModule)
  },
  {
    path: 'previous-policy',
    loadChildren: () => import('./modals/previous-policy/previous-policy.module').then( m => m.PreviousPolicyPageModule)
  },
  {
    path: 'confirm-buy',
    loadChildren: () => import('./modals/confirm-buy/confirm-buy.module').then( m => m.ConfirmBuyPageModule)
  },
  {
    path: 'health-quote-enquiry',
    loadChildren: () => import('./pages/healthInsurance/health-quote-enquiry/health-quote-enquiry.module').then( m => m.HealthQuoteEnquiryPageModule)
  },
  {
    path: 'quote',
    loadChildren: () => import('./pages/healthInsurance/quote/quote.module').then( m => m.QuotePageModule)
  },
  {
    path: 'health-quote-details',
    loadChildren: () => import('./pages/healthInsurance/health-quote-details/health-quote-details.module').then( m => m.HealthQuoteDetailsPageModule)
  },
  {
    path: 'health-proposal',
    loadChildren: () => import('./pages/healthInsurance/health-proposal/health-proposal.module').then( m => m.HealthProposalPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./modals/otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'enquiry-popup',
    loadChildren: () => import('./modals/enquiry-popup/enquiry-popup.module').then( m => m.EnquiryPopupPageModule)
  },
  {
    path: 'motor',
    loadChildren: () => import('./modals/motor/motor.module').then( m => m.MotorPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./modals/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'exam-result',
    loadChildren: () => import('./pages/exam-result/exam-result.module').then( m => m.ExamResultPageModule)
  },
  {
    path: 'policy-preview',
    loadChildren: () => import('./pages/policy-preview/policy-preview.module').then( m => m.PolicyPreviewPageModule)
  },
  {
    path: 'confirm-quote',
    loadChildren: () => import('./pages/confirm-quote/confirm-quote.module').then( m => m.ConfirmQuotePageModule)
  },
  {
    path: 'make-payment',
    loadChildren: () => import('./pages/make-payment/make-payment.module').then(m => m.MakePaymentPageModule)
  },
  {
    path: 'policy-summary',
    loadChildren: () => import('./pages/policy-summary/policy-summary.module').then( m => m.PolicySummaryPageModule)
  },
  {
    path: 'payment-failed',
    loadChildren: () => import('./pages/payment-failed/payment-failed.module').then( m => m.PaymentFailedPageModule)
  },
  {
    path: 'payment-failed',
    loadChildren: () => import('./pages/payment-failed/payment-failed.module').then( m => m.PaymentFailedPageModule)
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/coming-soon/coming-soon.module').then( m => m.ComingSoonPageModule)
  },
  {
    path: 'coming-soon-training',
    loadChildren: () => import('./pages/coming-soon-training/coming-soon-training.module').then( m => m.ComingSoonTrainingPageModule)
  },
  {
    path: 'impersonate',
    loadChildren: () => import('./pages/impersonate/impersonate.module').then( m => m.ImpersonatePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'impersonate-confirm',
    loadChildren: () => import('./modals/impersonate-confirm/impersonate-confirm.module').then( m => m.ImpersonateConfirmPageModule)
  },
  {
    path: 'leads-proposal',
    loadChildren: () => import('./pages/leads-proposal/leads-proposal.module').then( m => m.LeadsProposalPageModule)
  },
  {
    path: 'add-to-leads',
    loadChildren: () => import('./modals/add-to-leads/add-to-leads.module').then( m => m.AddToLeadsPageModule)
  },
  {
    path: 'kyc-submit',
    loadChildren: () => import('./modals/kyc-submit/kyc-submit.module').then( m => m.KycSubmitPageModule)
  },
  {
    path: 'health-policy-preview',
    loadChildren: () => import('./pages/healthInsurance/health-policy-preview/health-policy-preview.module').then( m => m.HealthPolicyPreviewPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
